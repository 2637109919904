import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from "framer-motion"
import Fade from "react-reveal/Fade"

import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/ContentWrapper"
import { Link } from "gatsby"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 4rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .section-title {
      margin-bottom: 2rem;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text-available-wrapper {
      display: flex;
      align-items: baseline;
    }
    .text-available {
      font-size: 1.25rem;
      font-weight: 500;
      color: blue;
      margin-right: 2rem;
    }
    .cta-btn {
      width: auto;
      height: auto;
      font-weight: 700;
      border-radius: ${({ theme }) => theme.borderRadius};
      border: 0.125rem solid ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.background};
      transition: 20ms ease-out;
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
      margin: 0;
      &:hover {
        background: ${({ theme }) => theme.colors.primary};
        color: #ffffff;
      }
    }

    .text-content {
      width: 100%;
      max-width: 31.25rem;
    }
    .image-content {
      width: 100%;
      max-width: 18rem;
      margin-top: 4rem;
      margin-left: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 2rem;
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const About = ({ content }) => {
  const { frontmatter, body } = content[0].node

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)
  const iVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <StyledSection id="about">
      <Fade>
        <StyledContentWrapper>
          <div className="inner-wrapper">
            <h3 className="section-title">{frontmatter.title}</h3>
            <div className="text-content">
              <div className="text-available-wrapper">
                <h4 className="text-available">
                  <em>I'm available for work!</em>
                </h4>
                <Link className="cta-btn" to="/#contact">
                  Contact Me
                </Link>
              </div>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
          <div className="image-content">
            <Img
              className="about-author"
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          </div>
        </StyledContentWrapper>
      </Fade>
    </StyledSection>
  )
}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About
